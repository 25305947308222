import { Storage } from "@lib/storage";
import React, { ReactNode, createContext, useCallback, useState } from "react";
import { log } from "../utils/log";

type ContextValue = {
  getItem: <T>(key: string, deprecatedKey?: string) => Promise<T | null>;
  setItem: <T>(key: string, item: T) => void;
  removeItem: (key: string) => void;
  clear: () => void;
};

export const StorageContext = createContext<ContextValue>({
  getItem: <_ extends any>(_: string, __?: string) => { throw new Error("StorageContext not initialized") },
  setItem: <T extends any>(_: string, __: T) => { },
  removeItem: (_: string) => { },
  clear: () => { },
});

type Props = {
  children: ReactNode;
};

export function StorageProvider({ children }: Props) {
  const [store] = useState<Storage>(() => new Storage());

  const getItem = useCallback(
    async <T extends any>(key: string, deprecatedKey?: string): Promise<T | null> => {
      log("StorageProvider - getItem", key);
      return await store.getItem<T>(key, deprecatedKey);
    },
    [store]
  );

  const setItem = useCallback(
    async <T extends any>(key: string, item: T) => {
      console.log("Setting item", key);
      await store.setItem<T>(key, item);
    },
    [store]
  );

  const removeItem = useCallback(
    async (key: string) => {
      await store.removeItem(key);
    },
    [store]
  );

  const clear = useCallback(async () => {
    await store.clear();
  }, [store]);

  return <StorageContext.Provider value={{ getItem, setItem, removeItem, clear }}>{children}</StorageContext.Provider>;
}
