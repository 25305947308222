import { captureException } from "@sentry/nextjs";
import { config } from "./config";

export const reportError = (error: Error): void => {
  if (config.sentryEnabled) {
    captureException(error, {
      level: "error",
    });
  } else {
    console.error(error);
  }
};
